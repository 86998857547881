import React, { useContext } from "react";
import { Content, MainContainer } from "../../layout";
import { useTranslation } from "react-i18next";
import { TopUpForm } from "../../components/form";
import { WalletInfo } from '../../components/wallet'
import { AuthContext } from "../../auth";


const TopUpPage = () => {
  const { t } = useTranslation();
  const { isLogin } = useContext(AuthContext)

  return (
    <MainContainer isLogin={isLogin}>
      <Content minHSceen={true}>
        <div className="w-full h-full flex justify-center items-center topup-container">
          <div className="w-full h-full flex flex-col justify-center items-center">
            <div className="container flex justify-center">
              <p className="text-3xl text-[#E6564E] text-center leading-loose lg:leading-loose">
                {'TopUp Coin'}
              </p>
            </div>
            <TopUpForm t={t} />
          </div>
          <WalletInfo t={t} />
        </div>
      </Content>
    </MainContainer>
  );
};
export default TopUpPage;
