import React, { useEffect, useState } from 'react'
// import dateFormat from 'dateformat'
import { walletInfo } from '../../services/api'
import './WalletInfo.scss'

const WalletInfo = (props) => {
  const [init, setInit] = useState(false)
  const [info, setInfo] = useState()

  useEffect(() => {
    initialize()
  })

  const initialize = async () => {
    try {
      if (init) return
      setInit(true)
      const info = await walletInfo()
      setInfo(info.data)
    } catch (error) {
      console.log('initialize error', error)
      setInit(false)
    }
  }

  if (info == null) {
    return (<div className="w-full h-full flex flex-col justify-center items-center">
      <div className="container flex justify-center">
        <p className="text-3xl text-[#E6564E] text-center leading-loose lg:leading-loose">
          {'Loading'}
        </p>
      </div>
    </div>)
  }

  return (<div className="w-full h-full flex flex-col justify-center items-center">
    <div className="container flex justify-center">
      <p className="text-3xl text-[#E6564E] text-center leading-loose lg:leading-loose">
        {info.id}
      </p>
    </div>
    <div>Balance: {info.summary}</div>
    {/* <div className="container flex justify-center">
      <p className="text-3xl text-[#E6564E] text-center leading-loose lg:leading-loose">
        {'Transaction History'}
      </p>
    </div>
    <div className='transactionHistory'>
      <table>
        <thead>
          <tr>
            <th className='date'>Date</th>
            <th>Transfer To/From</th>
            <th className='number'>Amount</th>
          </tr>
        </thead>
        <tbody>
          {info != null && info.transaction != null && info.transaction.map((t, i) => (
            <tr key={i}>
              <td className='date'>{dateFormat(new Date(t.tnx_ts), 'dd/mm/yyyy HH:MM:ss')}</td>
              <td>{t.type === 'WITHDRAW' ? t.tnx_to : t.tnx_by}</td>
              <td className='number'>{Number(t.value).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div> */}
  </div>)
}

export default WalletInfo
