import { createContext, useState } from "react";
import { FAIL, SUCCESS } from "./constant";
import { loginWithUser } from "./services/api";
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  let [user, setUser] = useState(null);
  let [isLoading, setIsloading] = useState(false);

  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token
  };

  const [token, setToken] = useState(getToken());
  const [isLogin, setIsLogin] = useState(getToken() !== undefined ? true : false);


  const saveToken = userToken => {
    localStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken.token);
  };


  let login = async (user, pass) => {
    setIsloading(true);
    const { data, status } = await loginWithUser(user, pass);
    if (status === 200) {
      setUser({
        type: SUCCESS,
        data: data,
      });
      saveToken(data);
      setIsLogin(true);

    } else {
      setUser({
        type: FAIL,
        data: data,
      });
      setIsLogin(false)
    }
    setIsloading(false);
  };

  let logout = () => {
    resetContext();
  };

  let resetContext = ()=>{
    setUser(null);
    setIsLogin(false);
    localStorage.removeItem('token');
    setToken(getToken());
  }


  let value = { user, isLoading, login, logout, token, isLogin };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
