const Alert = ({ text, type })=>{
    return(
        <>
        {
            text ? <span className="text-red-500 text-center">{text}</span> : null
        }
        </>
    )
}

export default Alert;