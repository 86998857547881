import React, { useContext } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { ROUTES } from "./constant";

import { AuthContext } from './auth'

const ProtectedRoutes = () => { 
    const location = useLocation();
    const { isLogin } = useContext(AuthContext);

    console.log("initial ProtectedRoutes")
    console.log('isLogin',isLogin);


    return (
        isLogin ? <Outlet />
        : <Navigate to={ROUTES.LOGIN} replace state={{ from: location }} />
    )
}
export default ProtectedRoutes;