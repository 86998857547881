import React, { useContext, useEffect, useState } from "react";
import dateFormat from "dateformat";
import { Content, MainContainer } from "../../layout";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "../../auth";
import { getCustomerEarnInPeriod, walletEarn } from "../../services/api";

const TextError = ({ text }) => {
  return (
    <div className="alert bg-transparent">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 text-error"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <span className="text-error">{text}</span>
      </div>
    </div>
  );
};

const TopUpConfirm = () => {
  const { isLogin } = useContext(AuthContext);
  const [errorMsg, setErrorMsg] = useState();
  let [searchParams] = useSearchParams();
  let phone = searchParams.get("phone");
  let amount = searchParams.get("amount");
  const navigator = useNavigate();

  const submitToEarnCouin = async()=>{
    if (phone && amount) {
      const { status, data } = await walletEarn(phone, amount);
      if (status === 200) {
        navigator(`/topup-success?phone=${phone}&amount=${amount}`, {
          replace: true
        });
      } else {
        setErrorMsg(data?.message);
      }
    }
  }

  const periodFrom = new Date('2022-07-26T00:00:00+07:00')
  const periodTo = new Date('2022-07-30T23:59:59+07:00')
  const [init, setInit] = useState(false);
  const [customerEarn, setCustomerEarn] = useState();

  useEffect(() => {
    initialize()
  })

  const isInPeriod = () => {
    const now = new Date().getTime()
    return now >= periodFrom.getTime() && now <= periodTo.getTime()
  }

  const initialize = async () => {
    try {
      if (init) return
      setInit(true)

      if (!isInPeriod()) {
        setCustomerEarn(0)
        return
      }

      const res = await getCustomerEarnInPeriod(phone, periodFrom, periodTo)
      setCustomerEarn(res.data)
    } catch (error) {
      setInit(false)
    }
  }

  if (customerEarn == null) {
    return <MainContainer isLogin={isLogin}>
      <div className="w-full h-full flex flex-col justify-center items-center">
        <p className="text-2xl text-[#E6564E] text-center leading-loose lg:leading-loose">Loading</p>
      </div>
    </MainContainer>
  }

  return (
    <MainContainer isLogin={isLogin}>
      <Content minHSceen={true}>
        <div className="w-full h-full flex flex-col justify-center items-center">
          <div className="container justify-center">
            {customerEarn > 0 && <p className="text-2xl text-[#E6564E] text-center leading-loose lg:leading-loose">
              {`This number already earned ${customerEarn} in period ${dateFormat(periodFrom, 'dd/mm/yyyy')} - ${dateFormat(periodTo, 'dd/mm/yyyy')}`}
              </p>}
            <p className="text-2xl text-[#E6564E] text-center leading-loose lg:leading-loose">
              {`Are you sure to top up coin ${amount} coins for mobile ${phone} ?`}
            </p>
          </div>
          <div className="card flex-shrink-0 w-full max-w-xl">
            <div className="card-body">
             {errorMsg ? <TextError text={errorMsg} /> : null}
              <div className="form-control relative"></div>
              <div className="form-control mt-6">
                <button className="btn btn-primary rounded-full" onClick={()=>submitToEarnCouin()}>
                  {"OK"}
                </button>
              </div>
              <div className="form-control mt-6">
                <button className="btn bg-[#E2E2E2] text-[#000000] rounded-full" onClick={()=> navigator(-1)}>
                  {"Cancel"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    </MainContainer>
  );
};
export default TopUpConfirm;
