import React, { useContext } from "react";
import { Content, MainContainer } from "../../layout";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "../../auth";


const TopUpSuccess = () => {
  const { t } = useTranslation();
  const { isLogin } = useContext(AuthContext);
  let [searchParams] = useSearchParams();
  let phone = searchParams.get("phone");
  let amount = searchParams.get("amount");
  const navigator = useNavigate();

  return (
    <MainContainer isLogin={isLogin}>
     <Content minHSceen={true}>
        <div className="w-full h-full flex flex-col justify-center items-center">
          <div className="container flex justify-center">
            <p className="text-2xl text-[#E6564E] text-center leading-loose lg:leading-loose">
              {`Top up coin ${amount} coins for mobile ${phone} Completed`}
            </p>
          </div>
          <div className="card flex-shrink-0 w-full max-w-xl">
            <div className="card-body">
              <div className="form-control relative"></div>
              <div className="form-control mt-6">
                <button className="btn btn-primary rounded-full" onClick={()=> navigator('/topup',{replace: true})}>
                  {t('ok')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    </MainContainer>
  );
};
export default TopUpSuccess;
