import React from 'react';
import styled from '@emotion/styled'
import './content.scss';
import 'animate.css';

const Content = ({minHSceen,appBg,bgRight,coinGif,children,styles, hero}) => {
   
  const topClassName = `hero ${minHSceen ? ' h-screen ': ''} ${appBg ? 'app-bg ': ''}`;
  const heroOverlayTop = `${bgRight? 'hero-overlay logo-countdown ':''}`;
  const ParentContent = styled.div`${({ styles }) => styles}`;

  return <>
  {
    hero ? <ParentContent styles={styles} className={topClassName}>
    <div className={heroOverlayTop}/>
      {coinGif ? <div className="hero-overlay bg-coin-gif bg-coin-bg-opacity animate__animated animate__fadeIn"/> : null}
      <div className="container mx-auto text-center text-neutral-content hero-h">
        {children}
      </div>
  </ParentContent>: <div className="container h-full mx-auto text-center text-neutral-content relative">{children}</div>
  }
   
  </>
}

export default Content;