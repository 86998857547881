import { useContext, useEffect, useState } from "react";
import { Content, MainContainer } from "../../layout";
import { useTranslation } from "react-i18next";
import { PasswordInput } from "../../components/form";
import {  useNavigate } from "react-router-dom";
import { AuthContext } from "../../auth";
import { FAIL } from "../../constant";
import { Alert } from "../../components";

const ButtonLoading = ({ t, isLoading }) => {
  const btnClass = `btn btn-primary rounded-full ${isLoading ? "loading" : ""}`;
  return (
    <button type="submit" className={btnClass}>
      {t("login.t")}
    </button>
  );
};

const LoginPage = () => {
  const { t } = useTranslation();
  const { user, login, token, isLogin } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    let formData = new FormData(event.currentTarget);
    let userForm = formData.get("user");
    let passForm = formData.get("pass");
    await login(userForm, passForm);
    setLoading(false);
  };

  useEffect(() => {
    console.log("isLogin", isLogin);
    if (isLogin) {
      navigate('/topup', { replace: true });
    }
  }, [user, token, navigate, isLogin]);

  return (
    <MainContainer>
      {!isLogin ? (
        <>
          <Content minHSceen={true}>
            <div className="w-full h-full flex flex-col justify-center items-center">
              <div className="container flex justify-center">
                <p className="text-3xl text-[#E6564E] text-center leading-loose lg:leading-loose">
                  {"Admin "}{t("login.t")}
                </p>
              </div>
              <div className="card flex-shrink-0 w-full max-w-xl">
                <form
                  className="card-body"
                  name="loginfrom"
                  onSubmit={handleSubmit}
                >
                  {user && user?.type === FAIL ? (
                    <Alert text={user?.data?.message} />
                  ) : null}
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">{t("info.phone")}</span>
                    </label>
                    <input
                      name="user"
                      autoComplete="phone"
                      type="text"
                      placeholder={t("info.phone")}
                      data-lpignore="true"
                      className="input input-bordered border-2 border-[#E6564E] text-[#141519] bg-[#F2F2F2] rounded-full btn-outline hover:bg-white hover:text-black"
                    />
                  </div>
                  <div className="form-control relative">
                    <label className="label">
                      <span className="label-text">{t("login.password")}</span>
                    </label>
                    <PasswordInput
                      name="pass"
                      placeholder={t("login.password")}
                    />
                  </div>
                  <div className="form-control mt-6">
                    <ButtonLoading t={t} isLoading={loading} />
                    {/* <div className="flex justify-between">
                      <label className="label">
                        <Link
                          to="/forgot"
                          className="label-text-alt link link-hover"
                        >
                          {t("login.forgot")}
                        </Link>
                      </label>
                      <label className="label">
                        <Link
                          to="/register"
                          className="label-text-alt link link-hover"
                        >
                          {t("register")}
                        </Link>
                      </label>
                    </div> */}
                  </div>
                </form>
              </div>
            </div>
          </Content>
          {/* <Footer /> */}
        </>
      ) : null}
    </MainContainer>
  );
};
export default LoginPage;
