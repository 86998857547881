export const SUCCESS = 'success';
export const FAIL = 'fail';

export const ROUTES = {
    SETPASSWORD: '/setpassword',
    DASHBOARD: '/dashboard',
    REGISTER: '/register',
    USER_INFO: '/userinfo',
    COUNTDOWN: '/countdown',
    LOGIN: '/login',
    OTP: '/otp',
    SUCCESS: '/success',
    FORGOT: '/forgot',
    STATUS: '/status',
}