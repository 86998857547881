import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { MenuIcon } from "@heroicons/react/outline";
import navLogo from "../../assets/logo.png";
import { SwitchLang } from "../../components";
import "./navbar.scss";

import { AuthContext } from "../../auth";

const DropDownMenu = ({ t , logout }) => {
  return (
    <div className="dropdown dropdown-end">
      <label tabIndex={0} className="btn btn-ghost m-1">
        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
      </label>

      <ul
        tabIndex={0}
        className="dropdown-content menu p-2 shadow bg-white rounded-box w-52 z-auto"
      >
        <li onClick={()=>logout()}>
          <span className="text-black">{t("logout")}</span>
        </li>
      </ul>
    </div>
  );
};

const MenuList = ({ t, isLogin }) => {
  console.log("MenuList isLogin", isLogin);
  const { logout } = useContext(AuthContext);
  const navRight = `${
    isLogin
      ? "grid grid-cols-2 gap-4 sm:grid-cols-2 "
      : "grid grid-cols-1 gap-4 sm:grid-cols-2"
  } text-sm text-white`;

  return (
    <div className={navRight}>
      {!isLogin ? (
        <>
          <div className="flex flex-col justify-center items-center shrink-0">
            <Link
              className="px-4 py-2 font-semibold text-sm text-white hover:text-[#E6564E]  rounded-full shadow-sm"
              to="/login"
            >
              {t("login.t")}
            </Link>
          </div>
          <SwitchLang />
        </>
      ) : (
        <>
          <SwitchLang />
          <DropDownMenu t={t} logout={logout}/>
        </>
      )}
    </div>
  );
};

const NavBar = ({ hideMenu, isLogin, t }) => {
  const NavBarClassName = `navbar bg-base-100 nav-home-bg sticky top-0 z-[70] ${
    isLogin ? " shadow-xl " : ""
  }`;
  return (
    <nav className={NavBarClassName}>
      <div className="navbar-start">
        <div className="navbar-start nav-logo">
          <Link to="/countdown">
            <img to="/" src={navLogo} alt="navLogo" />
          </Link>
        </div>
      </div>
      {/* Desktop Nav menu */}
      <div className="navbar-end hidden lg:flex">
        {hideMenu ? <SwitchLang /> : <MenuList t={t} isLogin={isLogin} />}
      </div>
      {/* Mobile Nav menu */}
      <div className="navbar-end lg:hidden sm:flex md:flex">
        <label htmlFor="my-drawer" className="drawer-button mr-3">
          <MenuIcon
            htmlFor="my-drawer"
            className="block h-6 w-6"
            aria-hidden="true"
          />
        </label>
      </div>
    </nav>
  );
};
export default NavBar;
