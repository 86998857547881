import React from "react";
import "./config/init_firebase";
import { Routes, Route } from "react-router-dom";
import { AuthProvider } from "./auth";

import {
  LoginPage,
  TopUpPage,
  TopUpConfirm,
  TopUpSuccess
} from "./pages";

import ProtectedRoutes from "./ProtectedRoutes";

const App = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path="*" element={<LoginPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="topup" element={<TopUpPage />} />
          <Route path="topup-confirm" element={<TopUpConfirm />} />
          <Route path="topup-success" element={<TopUpSuccess />} />
        </Route>
      </Routes>
    </AuthProvider>
  );
};
export default App;
