export default class ConfigApi {
    constructor(EnvFile) {
        this.API_KEY = EnvFile.REACT_APP_BACKEND_API_KEY;
        this.BASE_URI = EnvFile.REACT_APP_BACKEND_BASE_URI;
    }
    get apiKey(){
        return this.API_KEY;
    }
    get baseUrl(){
        return this.BASE_URI;
    }
}
