import React, { useContext } from "react";
import { XIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import NavBar from "../navbar";
import { SwitchLang } from "../../components";
import { AuthContext } from "../../auth";

const toggleDrawer = () => {
  document.getElementById("my-drawer").click();
};

const MainContainer = ({ hideMenu, children, isLogin, term, justify }) => {
  const { t } = useTranslation();
  const { logout } = useContext(AuthContext);

  const justifyStyle = isLogin ? 'justify-start' : 'justify-between';
  return (
    <>
      <div className="drawer min-h-screen drawer-end font-notosansthai ">
        <input id="my-drawer" type="checkbox" className="drawer-toggle" />
        <div className={`drawer-content flex flex-col ${justifyStyle}`}>
          <NavBar hideMenu={hideMenu} isLogin={isLogin} t={t} />
          {children}
        </div>
       {/* Mobile Nav menu */}
        <div className="drawer-side">
          <label htmlFor="my-drawer" className="drawer-overlay"></label>
          <ul className="menu p-4 overflow-y-auto w-60 md:w-80 bg-[#49494A] text-base-content">
            <div className="px-4 py-3">
              <div className="flex justify-center items-center shrink-0">
                <SwitchLang />
                <XIcon
                  className="block h-6 w-6 ml-auto"
                  aria-hidden="true"
                  onClick={toggleDrawer}
                />
              </div>
            </div>

            {isLogin ? (
              <>
                <li onClick={() => logout()}>
                  <span className="text-white">{t("logout")}</span>
                </li>
              </>
            ) : null}
          </ul>
        </div>
      </div>
    </>
  );
};

export default MainContainer;
