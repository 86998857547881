import React, { useState } from "react";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";
import "./password.scss";

const PasswordInput = ({ id, name,placeholder, autoComplete, register }) => {
  const [open, setOpen] = useState(false);

  // handle toggle
  const toggle = () => {
    setOpen(!open);
  };
  
  name = name ?? 'pass'  
  placeholder = placeholder ?? 'password'  
  autoComplete = autoComplete ?? 'current-password' 
  
  return (
    <>
    {
      register ? <input
      id={id}
      name={name}
      type={open === false ? "password" : "text"}
      placeholder={placeholder}
      autoComplete={autoComplete}
      {
        ...register(`${name}`)
      }
      className="input input-bordered border-2 border-[#E6564E] text-[#141519] bg-[#F2F2F2] rounded-full btn-outline hover:bg-white hover:text-black"
    />:
    <input
        id={id}
        name={name}
        type={open === false ? "password" : "text"}
        placeholder={placeholder}
        autoComplete={autoComplete}
        className="input input-bordered border-2 border-[#E6564E] text-[#141519] bg-[#F2F2F2] rounded-full btn-outline hover:bg-white hover:text-black"
      />
    }
      
      {open === false ? (
        <EyeIcon
          className="eyeicon absolute h-6 w-6 top-12 right-5 ml-auto"
          onClick={toggle}
        />
      ) : (
        <EyeOffIcon
          className="eyeicon absolute h-6 w-6 top-12 right-5 ml-auto"
          onClick={toggle}
        />
      )}
    </>
  );
};
export default PasswordInput;
