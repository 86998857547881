import axios from 'axios';
import '../config/init_firebase';
//init api config
import ConfigApi from './config';

const appConfig = new ConfigApi(process.env);

const BASE_URI = appConfig.baseUrl;
const API_KEY = appConfig.apiKey;

const getToken = () => {
  const tokenString = localStorage.getItem('token');
  const userToken = JSON.parse(tokenString);
  return userToken?.token;
};

export async function getHeaderCofing(accessToken) {
  let headers = {
    'api-key': `${API_KEY}`
  };
  if (accessToken) {
    headers = {
      ...headers,
      Authorization: `Bearer ${accessToken}`
    };
  } else {
    headers = {
      ...headers
    };
  }
  return {
    headers
  };
}

async function handleAuth(response) {
  console.log('error', response);
  const { status } = response;
  if (status === 401) {
    await Logout();
    localStorage.removeItem('token');
    window.location.assign('/login');
  }
}

export const loginWithUser = async (user, pass) => {
  try {
    const apiUrl = `${BASE_URI}/auth/sign-in`;
    const config = await getHeaderCofing();
    const payload = { username: user, password: pass };
    const { data } = await axios.post(apiUrl, payload, config);
    return data;
  } catch (error) {
    // throw new Error(error);
    console.error(error);
    return error.response;
  }
};

export const walletInfo = async () => {
  try {
    const apiUrl = `${BASE_URI}/wallet/info`;
    const token = await getToken();
    const config = await getHeaderCofing(token);
    const { data } = await axios.get(apiUrl, config);
    return data;
  } catch (error) {
    // throw new Error(error);
    await handleAuth(error.response);
    console.error(error);
    return error.response;
  }
};

export const getCustomerEarnInPeriod = async (
  phone,
  from = new Date(),
  to = new Date()
) => {
  try {
    const apiUrl = `${BASE_URI}/wallet/customer-earn-in-period`;
    const token = await getToken();
    const config = await getHeaderCofing(token);
    const payload = {
      phone,
      from,
      to
    };
    const { data } = await axios.post(apiUrl, payload, config);
    return data;
  } catch (error) {
    // throw new Error(error);
    await handleAuth(error.response);
    console.error(error);
    return error.response;
  }
};

export const walletEarn = async (phone, amount) => {
  try {
    const apiUrl = `${BASE_URI}/wallet/customer-earn`;
    const token = await getToken();
    const config = await getHeaderCofing(token);
    const payload = {
      phone: phone,
      value: parseFloat(amount)
    };
    console.log('payload, config)', payload, config);
    const { data } = await axios.post(apiUrl, payload, config);
    console.log('data', data);
    return data;
  } catch (error) {
    // throw new Error(error);
    await handleAuth(error.response);

    console.error(error);
    return error.response;
  }
};

export const Logout = async (token) => {
  try {
    const apiUrl = `${BASE_URI}/auth/sign-out`;
    const config = await getHeaderCofing(token);
    const { data } = await axios.get(apiUrl, config);
    if (data) {
      localStorage.removeItem('token');
    }
    return data;
  } catch (error) {
    // throw new Error(error);
    // await handleAuth(error.response);
    console.error(error);
    return error.response;
  }
};
