import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { AuthContext } from "../../../auth";
import { useNavigate } from "react-router-dom";

const TopUpForm = ({ t }) => {
  const { logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const formSchema = Yup.object().shape({
    phoneNumber: Yup.string().required("Please fill phone number")
    .min(10, "phone number must be at 10 digit"),
    amountField: Yup.string()
      .required("Amount is required")
  });

  const formOptions = { resolver: yupResolver(formSchema),defaultValues: {
    phoneNumber: "",
    amountField: "500"
  } };
  const { register, handleSubmit, reset, formState: {errors} } = useForm(formOptions);
  const navigator = useNavigate();

  const onSubmit = async (fromData) => {
    setLoading(true);
    console.log("fromData", fromData);
    const { phoneNumber, amountField } = fromData;
    if (phoneNumber && amountField) {
      navigator(`/topup-confirm?phone=${phoneNumber}&amount=${amountField}`);
    }
    setLoading(false);
  };

  return (
    <div className="card flex-shrink-0 w-full max-w-xl">
      <form className="card-body" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-control relative">
          <label className="label">
            <span className="label-text">{"Mobile"}</span>
          </label>
          <input
            autoComplete="phoneNumber"
            type="number"
            name="phoneNumber"
            placeholder="088-888-8888"
            {...register('phoneNumber')}
            className="input input-bordered border-2 border-[#E6564E] text-[#141519] bg-[#F2F2F2] rounded-full btn-outline hover:bg-white hover:text-black"
          />
          {errors.phoneNumber && (
            <p className="pl-5 text-error text-left text-sm">
              {errors.phoneNumber?.message}
            </p>
          )}
        </div>
        <div className="form-control relative">
          <label className="label">
            <span className="label-text">{"Amount"}</span>
          </label>
          <input
            autoComplete="amountField"
            type="number"
            name="amountField"
            placeholder="1000"
            {...register('amountField')}
            className="input input-bordered border-2 border-[#E6564E] text-[#141519] bg-[#F2F2F2] rounded-full btn-outline hover:bg-white hover:text-black"
          />
        </div>
        {errors.amountField && (
          <p className="pl-5 text-error text-left text-sm">
            {errors.amountField?.message}
          </p>
        )}
        <div className="form-control mt-6">
          {loading ? (
            <button className="btn btn-primary rounded-full loading">
              {t("confirm")}
            </button>
          ) : (
            <button type="submit" className="btn btn-primary rounded-full">
              {t("confirm")}
            </button>
          )}
        </div>
        <div className="form-control mt-6">

        <button type="button" className="btn btn-primary rounded-full" onClick={()=> reset()}>
              {t('clear')}
            </button>
            </div>
            <div className="form-control mt-6">

            <button type="button" className="btn btn-primary rounded-full" onClick={()=>logout()}>
            {t("logout")}
            </button>
            </div>
      </form>
    </div>
  );
};
export default TopUpForm;
